import { ContentContainerBackoffice, RowButtonLinkV2, RowContainerV2, Text } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { BackofficeSectionTitle } from '../../../../atoms/backoffice-section-title'

export default memo(function BackofficeInsurancePage() {
  return (
    <ContentContainerBackoffice>
      <BackofficeSectionTitle>Documentation</BackofficeSectionTitle>
      <Text variant="h6">Assurance</Text>
      <RowContainerV2>
        <RowButtonLinkV2
          to="/bak/documentation/general-terms"
          primaryText="Documents statiques"
          secondaryText="Conditions générales, IPID, documents porteur de risque, etc."
        />
        <RowButtonLinkV2
          to="/bak/documentation/exclusions"
          primaryText="Exclusions"
          secondaryText="Toutes les exclusions et le wording correspondant"
        />
        <RowButtonLinkV2
          to="/bak/documentation/activity-specific-questions"
          primaryText="Questions spécifiques à une activité"
          secondaryText="Toutes les questions specifiques à une activité et les exclusions associées"
        />
        <RowButtonLinkV2
          to="/bak/documentation/subscription-funnel-texts"
          primaryText="Parcours de souscription"
          secondaryText="La plupart des textes du parcours de souscription"
        />
      </RowContainerV2>
      <Text variant="h6">Documentation technique</Text>
      <RowContainerV2>
        <RowButtonLinkV2
          to="/bak/tracking-doc"
          primaryText="Tracking"
          secondaryText="Evenements métiers envoyés à Segment et GTM"
        />
        <RowButtonLinkV2
          to="/bak/documentation/roles"
          primaryText="Roles & Permissions"
          secondaryText="Liste des rôles et descriptions des permissions associées"
        />
      </RowContainerV2>
    </ContentContainerBackoffice>
  )
})
